<template>
  <v-container fluid>
    <v-form ref="validationForm">
      <v-row justify="center" class="mt-2 mx-5">
        <v-col>
          <h1 class="secondary--text">Exclusão de Titular</h1>
        </v-col>
        <v-col class="text-h5 primary--text mb-5" cols="12">
          <div>Preencha os campos abaixo para concluir a exclusão do Titular</div>
        </v-col>

        <PlanInformation
          class="pb-0"
          :contractedPlanData="contractedPlanData"
          :isDetail="isFreeMovement ? false : true"
          :showHeaderPage="false"
          :showEligibility="false"
          :showStartValidityDate="false"
          :showEndValidityDate="false"
          :showCancellationReason="false"
          :showFamilyCode="false"
          :showFamilyCodeSequential="false"
          @observerContractedPlan="observerContractedPlan"
        />

        <PrincipalInformations
          class="pt-0"
          :holderInformationData="holderInformationData"
          :isFreeMovement="isFreeMovement"
          :isDetail="isFreeMovement ? false : true"
          :showHeaderPage="false"
          :showAdmissionDate="false"
          :showRegistrationPlate="true"
          :showCardNumber="true"
          @observerHolderInformationData="observerHolderInformationData"
        />

        <v-row class="mx-0">
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Código Familiar</label>
            <v-text-field
              outlined
              class="mt-2"
              v-model="familyCode"
              v-mask="`${ formatter.maskLimiter(10) }`"
              color="textPrimary"
              placeholder="Informe código familiar"
              :disabled="isDisabledFamilyCode"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Sequencial</label>
            <v-text-field
              outlined
              class="mt-2"
              v-model="familyCodeSequential"
              v-mask="`${ formatter.maskLimiter(5) }`"
              color="textPrimary"
              placeholder="Informe código sequencial"
              :disabled="isDisabledFamilyCodeSequential"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Data Fim de Vigência</label>
            <v-menu
              v-model="validityDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              left
              min-width="auto">
              <template
                v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  class="mt-2"
                  v-model="endDate"
                  append-icon="fas fa-calendar-alt"
                  placeholder="dd/mm/aaaa"
                  v-mask="'##/##/####'"
                  color="textPrimary"
                  validate-on-blur
                  v-bind="attrs"
                  v-on="on"
                  @click="getAllowedDates()"
                  readonly
                  clearable
                />
              </template>
              <AllowedDatePicker
                @date="updateValidityDate($event)"
                :allowedDates="allowedDates"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Motivo de Exclusão</label>
            <v-autocomplete
              v-model="exclusionReasonCode"
              :items="exclusionReasons"
              item-text="name"
              item-value="code"
              class="mt-2"
              placeholder="Selecione"
              outlined
              color="textPrimary"
              clearable
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Contributário</label>
            <v-autocomplete
              v-model="contributor"
              :items="contributorItems"
              item-text="text"
              item-value="value"
              class="mt-2"
              placeholder="Selecione"
              outlined
              color="textPrimary"
              @input="contributor === false ? planExtension = null : null"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Extensão do plano?</label>
            <v-autocomplete
              v-model="planExtension"
              :items="planExtensions"
              item-text="text"
              item-value="value"
              class="mt-2"
              placeholder="Selecione"
              outlined
              color="textPrimary"
              @input="planExtension === false ? contributionPeriod = null : null"
              :disabled="!contributor"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Período de contribuição, em meses</label>
            <v-text-field
              v-model="contributionPeriod"
              class="mt-2"
              placeholder="Ex.: 12"
              type="number"
              v-mask="'###'"
              @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
              @input="contributionPeriod = formatter.removeInvalidNumber(contributionPeriod)"
              id="fieldChecked"
              outlined
              color="textPrimary"
              :disabled="planExtension ===  true ? false : true"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Data demissão</label>
            <v-text-field
              outlined
              class="mt-2"
              v-model="resignationDate"
              append-icon="fas fa-calendar-alt"
              placeholder="dd/mm/aaaa"
              v-mask="'##/##/####'"
              color="textPrimary"
              :rules="resignationDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.requiredDate] : []"
              validate-on-blur
            />
          </v-col>
        </v-row>
      </v-row>
      <v-row >
        <div
          ref="uploadDocumentComponent" class="pa-12 mr-10" style="width: 100%;">
          <div>
            <p class="title">Upload de Documentos</p>
          </div>

          <UploadDocuments
            ref="UploadDocuments"
            :showEditAndSaveButton="false"
            :showDownloadButton="false"
            :movementRecordId="movementRecord ? movementRecord.id : null"
            :updateParent="true"
            :movementType="'DELETE'"
            :beneficiaryType="'HOLDER'"
            :documentParams="documentParams"
            :startSearch="startSearch"
            @searchEnded="startSearch = false"
            @observerDocumentData="documentsList = $event"
          />
        </div>
      </v-row>
    </v-form>

    <v-row v-if="beneficiaryData.dependents && beneficiaryData.dependents.length > 0" justify="center" class="mt-10 mx-5">
      <v-col class="text-h5 primary--text mb-5" cols="12">
        <div>Os dependentes abaixo serão excluídos juntamente com o Titular</div>
      </v-col>
    </v-row>

    <v-row justify="center" class="mx-8" v-if="beneficiaryData.dependents && beneficiaryData.dependents.length > 0">
      <v-row v-for="(item, index) in beneficiaryData.dependents" :key="index">
        <v-col cols="12">
          <v-card class="px-5" elevation="1">
            <v-row class="mt-2">
              <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                <label class="label">Nome</label>
                <v-text-field
                  class="mt-2"
                  :value="item.insuredName"
                  required
                  outlined
                  color="textPrimary"
                  readonly
                  disabled
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                <label class="label">Grau de Parentesco</label>
                <v-autocomplete
                  v-model="item.kinshipId"
                  class="mt-2"
                  :items="kinships"
                  item-text="description"
                  item-value="id"
                  outlined
                  color="textPrimary"
                  disabled
                  readonly
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                <label class="label">CPF</label>
                <v-text-field
                  class="mt-2"
                  :value="formatter.formatCpf(item.holderCpf)"
                  v-mask="'###.###.###-##'"
                  outlined
                  color="textPrimary"
                  readonly
                  disabled
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                <label class="label">Data de nascimento</label>
                <v-text-field
                  class="mt-2"
                  :value="formatter.formatDate(item.insuredBirthDate)"
                  color="textPrimary"
                  outlined
                  readonly
                  disabled
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>

    <v-dialog v-model="confirmReturnModal" persistent :max-width="600">
      <v-card class="overflow-y-auto pa-5">
        <v-card-title class="pa-5">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-icon large color="green">fa-check</v-icon>
              <v-row class="mb-3" justify="center" style="word-break: break-word">
                <v-col cols="12" align="center">
                  <label class="label primary&#45;&#45;text font-italic" style="word-break: break-word">
                    {{ isEdit ? 'Movimentação editada com sucesso!' : 'Movimentação cadastrada com sucesso!' }}
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-row class="ma-1 justify-center">
            <v-btn class="pa-7" color="#3B495B" style="color: white" @click="closeConfirmReturnModal()">
              IR PARA A LISTA DE MOVIMENTAÇÕES
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ReleaseMovementModal
      ref="ReleaseMovementModal"
      @close="closeConfirmReturnModal()"
      @confirm="updateStatus()"
    />

    <TransitionMovementModal
      ref="TransitionMovementModal"
      @makeNewMovement="backToSeachHolderDependentDeletePage"
      @goToMovementList="goToMovementPage"
    />

    <InfoMovementDialog
      :show="showReleaseInfo" :movementType="movementType"
      :messageType="releaseMessageType"
      :beneficiaryName="holderInformationData && holderInformationData.name ? formatter.formatToTitleCase(holderInformationData.name) :null" @close="closeConfirmReturnModal()"
    />

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>

    <div v-if="violations.length > 0" class="mt-10">
      <ViolationRulesAlert :violations="violations" />
    </div>

    <div v-if="isCriticizedCarrier || isCriticizedBroker" style="position: absolute; z-index: 1;">
      <ViewCriticizedViolationDialog />
    </div>

    <v-footer>
      <v-row class="d-flex justify-end my-6 mt-15">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2" class="pt-0">
          <v-btn
            v-if="!isEdit && !isCriticizedBroker && !isCriticizedCarrier"
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          ref="buttonNext"
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          :loading="isLoadingSave"
          :disabled="waitingDocumentUpload"
          @click="openViewCriticismAndInternalNotesModal()"
        >
          Concluir
        </v-btn>
      </v-row>
    </v-footer>

    <SnackbarCustomize ref="SnackbarCustomize" />

    <ViewCriticismAndInternalNotesModal
      ref="ViewCriticismAndInternalNotesModal"
      @setSnackbarCustomize="setSnackbarCustomize()"
      @confirmCorrectionMovement="confirmCorrectionMovement"
    />

    <CriticismHandlingModal ref="CriticismHandlingModal" @confirm="openViewCriticismAndInternalNotesModal" />
  </v-container>
</template>

<script>
import lodash from 'lodash';
import CancelationReasonService from '@/services-http/odoo/CancelationReasonService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import { store } from '@/shared/observable/store';
import ContractService from '@/services-http/contract/ContractService';
import PlanInformation from '@/components/Beneficiary/Contracted/PlanInformation.vue';
import KinshipService from '@/services-http/contract/KinshipService';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import PrincipalInformations from '@/components/Beneficiary/Holder/PrincipalInformations.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import InfoMovementDialog from '@/components/MovementDialog/InfoMovementDialog.vue';
import UserUtils from '@/shared/utils/user-utils';
import ReleaseMovementModal from '@/components/ReleaseMovement/ReleaseMovementModal.vue';
import ViewCriticismAndInternalNotesModal from '@/components/DashboardMovementRecords/Movements/Modals/ViewCriticismAndInternalNotes/ViewCriticismAndInternalNotesModal.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import CriticismHandlingModal from '@/components/CustomAlerts/CriticismHandlingModal.vue';
import AllowedDatePicker from '@/components/AllowedDatePicker/AllowedDatePicker.vue';
import CarrierService from '@/services-http/contract/CarrierService';
import ContractContributoryService from '@/services-http/contract/ContractContributoryService';
import moment from 'moment';
import TransitionMovementModal from '@/components/TransitionMovementModal/TransitionMovementModal.vue';
import { mapGetters, mapMutations } from 'vuex';
import UploadDocuments from '@/components/Beneficiary/Documents/UploadDocuments.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';

export default {
  name: 'BeneficiaryDelete',
  components: {
    InfoMovementDialog,
    ViolationRulesAlert,
    PrincipalInformations,
    PlanInformation,
    ViewCriticizedViolationDialog,
    ReleaseMovementModal,
    ViewCriticismAndInternalNotesModal,
    SnackbarCustomize,
    CriticismHandlingModal,
    AllowedDatePicker,
    TransitionMovementModal,
    UploadDocuments,
  },
  data: () => ({
    moment: moment,
    validityDateMenu: null,
    validityDatePicker: null,
    carrierService: null,
    contractContributoryService: null,
    allowedDates: [],
    isRHProtegido: false,
    waitingDocumentUpload: false,
    overlay: false,
    isFreeMovement: false,
    isLoadingSave: false,
    isDisabledFamilyCode: false,
    isDisabledFamilyCodeSequential: false,

    endDate: null,
    resignationDate: null,
    contributor: null,
    planExtension: null,
    contributionPeriod: null,
    exclusionReasonCode: null,
    familyCode: null,
    familyCodeSequential: null,

    documentsList: [],
    documentsData: [],
    exclusionReasons: [],
    kinships: [],
    planExtensions: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],
    contributorItems: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],

    holder: {
      document: null,
      name: null,
      registrationPlate: null,
      cardNumber: null,
    },
    beneficiaryData: {},
    payload: {},
    uploadInformationDocuments: {},
    holderInformationData: {},
    contractedPlanData: {},
    isCriticizedCarrier: false,
    isCriticizedBroker: false,
    confirmReturnModal: false,
    violations: [],
    hasAuthorityReleaseMovement: false,
    isEdit: false,
    showReleaseInfo: false,
    movementType: 'Exclusão',
    successReleaseMessageType: 'RELEASE_SUCCESS',
    errorReleaseMessageType: 'RELEASE_ERROR',
    releaseMessageType: '',
    waitingRulesApprovalIds: [],
    formatDate: '',
    formattedResignationDate: '',
    internalCriticisms: [],
    persistWithCriticism: false,
    contract: {},
    documentParams: {
      contractId: null,
      carrierId: null,
      hasGracePeriod: false,
      movementType: 'DELETE',
      beneficiaryType: 'HOLDER',
      benefitTypeCode: null,
    },
    startSearch: false,
    movementRecord: null,
  }),

  async beforeMount() {
    await this.loadSessionStorage();
    await this.mapBeneficiaryMovementRecord();
  },

  async mounted() {
    await this.loadAuthorityMovement();
    this.overlay = true;
    await this.loadKinships();
    this.loadCancelationReasons();
    // await this.loadDocumentType();
    await this.requestInsuranceCarrier();
    await this.loadContributoryInfo();
    this.startSearch = true;
    this.overlay = false;
  },

  mixins: [
    VerifyRoutesMixin,
    AuthorityManagementMixin,
  ],

  computed: {
    ...mapGetters({
      searchParams: 'handler/searchParams',
    }),
    getObservableStore() {
      return store.dialogInformation;
    },
  },

  watch: {
    getObservableStore(val) {
      if (val && !val.show) {
        if (val.viewMovements) {
          sessionStorage.setItem('movementRecordIds', JSON.stringify(val.data.movementRecordIds));
          this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
        }

        if (val.skipDuplicityCheck) {
          this.skipDuplicityCheck = true;
          this.openViewCriticismAndInternalNotesModal();
        }
      }
    },
  },

  methods: {
    ...mapMutations({
      setSearchParams: 'handler/setSearchParams',
    }),
    setSessionStoreData() {
      if (sessionStorage.getItem('searchParams')) {
        this.setLayout(JSON.parse(sessionStorage.getItem('searchParams')));
        sessionStorage.removeItem('searchParams')
      }
    },
    async getAllowedDates() {
      const fullPath = this.$router.currentRoute.fullPath.toString();
      await this.carrierService.GetAllowedDates([parseInt(this.contractedPlanData.contractId)], "DELETE").then(response => {
        if (response.status === 200) {
          this.allowedDates = response.data;
        }
      })
    },
    updateValidityDate(value) {
      this.endDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
      this.validityDateMenu = false;
    },
    verifyFreeMovement() {
      if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
        this.isFreeMovement = true;
      }
    },
    async loadSessionStorage() {
      if (sessionStorage.getItem('beneficiaryData')) {
        this.beneficiaryData = JSON.parse(sessionStorage.getItem('beneficiaryData'));
        this.contractedPlanData = {
          financialGroupId: Number(this.beneficiaryData.financialGroupId),
          contractId: Number(this.beneficiaryData.contractId),
          subContractId: Number(this.beneficiaryData.subcontractId),
          planId: Number(this.beneficiaryData.planId),
        };

        const holderInformationData = {};
        holderInformationData.document = this.formatter.formatCpf(this.beneficiaryData.insuredCpf);
        holderInformationData.name = this.formatter.formatToTitleCase(this.beneficiaryData.insuredName);
        holderInformationData.registrationPlate = this.beneficiaryData.registrationPlate;
        holderInformationData.cardNumber = this.beneficiaryData.insuredCardCode;
        holderInformationData.financialGroupId = this.beneficiaryData.financialGroupId;
        this.holderInformationData = holderInformationData;

        this.familyCode = this.beneficiaryData.memberFamilyCode;
        this.familyCodeSequential = this.beneficiaryData.memberFamilyCodeSeq;

        this.isDisabledFamilyCode = this.familyCode !== null;
        this.isDisabledFamilyCodeSequential = this.familyCodeSequential !== null;

        if(this.beneficiaryData.contractId) {
          await this.contractService.FindById(this.beneficiaryData.contractId).then(async (response) => {
            var contractResult = await response.data;

            if(contractResult){
              this.documentParams.carrierId = contractResult.carrier_id ? contractResult.carrier_id.id : null;
              this.documentParams.benefitTypeCode = contractResult.benefit_id ? contractResult.benefit_id.xipp_contract_record_type_code : null;
            }
          });
        }

        this.documentParams.contractId = this.beneficiaryData.contractId;
        this.documentParams.hasGracePeriod = this.beneficiaryData.hasGracePeriod ? true : false;
      }
      this.verifyTypeOperationForMovement();
    },
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async mapBeneficiaryMovementRecord() {
      if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.contractedPlanData = {
          financialGroupId: Number(this.movementRecord.financialGroupId),
          contractId: Number(this.movementRecord.contractId),
          subContractId: Number(this.movementRecord.subContractId),
          planId: Number(this.movementRecord.plan),
        };

        this.endDate = this.movementRecord.endDate ? this.formatter.formatDate(this.movementRecord.endDate) : null;
        this.resignationDate = this.movementRecord.resignationDate ? this.formatter.formatDate(this.movementRecord.resignationDate) : null;
        this.planExtension = !!this.movementRecord.planExtension;
        this.contributor = !!this.movementRecord.contributor;
        this.exclusionReasonCode = this.movementRecord.exclusionReasonCode;
        this.contributionPeriod = this.movementRecord.contributionPeriod;
        this.familyCode = this.movementRecord.familyCode;
        this.familyCodeSequential = this.movementRecord.familyCodeSequential;

        const holderInformationData = {};
        holderInformationData.document = this.movementRecord.holderDocumentNumber ? this.formatter.formatCpf(this.movementRecord.holderDocumentNumber) : null;
        holderInformationData.name = this.movementRecord.holderName ? this.formatter.formatToTitleCase(this.movementRecord.holderName) : null;
        holderInformationData.registrationPlate = this.movementRecord.registrationPlate ? this.movementRecord.registrationPlate : null;
        holderInformationData.cardNumber = this.movementRecord.beneficiaryCard;
        this.uploadInformationDocuments.insuranceCarrierId = this.movementRecord.insuranceCarrierId;
        this.holderInformationData = holderInformationData;

        this.isDisabledFamilyCode = this.familyCode !== null;
        this.isDisabledFamilyCodeSequential = this.familyCodeSequential !== null;

        this.documentParams.carrierId = this.movementRecord.insuranceCarrierId;
        this.documentParams.contractId = this.movementRecord.contractId;
        this.documentParams.hasGracePeriod = this.movementRecord.hasGracePeriod ? true : false;
        this.documentParams.beneficiaryType = 'HOLDER';
        this.documentParams.movementType = 'DELETE';

        if(this.movementRecord.contractId) {
          await this.contractService.FindById(this.movementRecord.contractId).then(async (response) => {
            var contractResult = await response.data;
            if(contractResult){
              this.documentParams.carrierId = contractResult.carrier_id ? contractResult.carrier_id.id : null;
              this.documentParams.benefitTypeCode = contractResult.benefit_id ? contractResult.benefit_id.xipp_contract_record_type_code : null;
            }
          });
        }

      }
    },
    async loadKinships() {
      await this.kinshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.kinships = response.data;
          this.kinships.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    loadCancelationReasons() {
      const query = '?refresh=false&beneficiaryTypeFilters=HOLDER';
      this.cancelationReasonService.FindAll(query).then((response) => {
        if ((response && response.data) && (response.data.records && response.data.records.length > 0)) {
          this.exclusionReasons = response.data.records;
        }
        this.exclusionReasons.sort((a, b) => (a.name > b.name ? 1 : -1));
      });
    },
    async requestInsuranceCarrier() {
      await this.contractService.FindById(this.beneficiaryData.contractId ? this.beneficiaryData.contractId : this.movementRecord.contractId).then(async (response) => {
          this.contract = await response.data;
        });

      if (!this.isCriticizedCarrier && !this.isEdit) {
          this.uploadInformationDocuments.insuranceCarrierId = this.contract.carrier_id.id;
      }

    },
    async loadDocumentType() {
      this.uploadInformationDocuments.movementType = 'DELETE';
      this.uploadInformationDocuments.beneficiaryType = 'HOLDER';
      this.uploadInformationDocuments.insuranceCarrierId = this.$route.query.insuranceCarrierId;
      const data = `movementType=${this.uploadInformationDocuments.movementType}&insuranceCarrierId=${this.uploadInformationDocuments.insuranceCarrierId}&beneficiaryType=${this.uploadInformationDocuments.beneficiaryType}`;
      await this.findDocumentType(data);
    },
    async findDocumentType(data) {
      await this.documentTypeService.FindAllDocumentType(data).then(async (response) => {
        this.documentsList = await response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
      });
    },
    async uploadDocument(item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png'))) {
          return;
        }
      }
      if (item.file !== null && typeof item.file !== 'undefined') {
        await this.clearDocument(item);
        const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
        this.waitingDocumentUpload = true;
        this.disableButton = true;
        this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
          this.documentsData.push({
            id: response.data,
            name: item.name,
            file: item.file,
            type: item.file.type,
          });
          // eslint-disable-next-line no-param-reassign
          item.idRecentlyUploaded = response.data;
          this.waitingDocumentUpload = false;
        }).catch(() => {
          this.waitingDocumentUpload = false;
        });
      } else {
        await this.clearDocument(item);
      }
    },
    async clearDocument(item) {
      if (item) {
        if (this.documentsData && this.documentsData.length > 0) {
          const documentIndex = this.documentsData.findIndex((document) => document.documentTypeId === item.documentTypeId);
          if (documentIndex >= 0) {
            this.documentsData.splice(documentIndex, 1);
          }
        }
      }
    },
    openViewCriticismAndInternalNotesModal(isContinue) {
      this.persistWithCriticism = !!isContinue;
      if (this.isCriticizedCarrier) {
        const queryParams = {
          movementRecord: this.movementRecord,
          isRHProtegido: this.isRHProtegido,
          viewType: 'confirmCorrectionMovement',
        };

        this.$refs.ViewCriticismAndInternalNotesModal.open(queryParams);
      } else {
        this.onClickFinish();
      }
    },
    confirmCorrectionMovement(data) {
      this.internalCriticisms = data;
      this.$refs.ViewCriticismAndInternalNotesModal.close();
      this.onClickFinish();
    },
    onClickFinish() {
      if (!this.$refs.UploadDocuments.validate()) return;

      if (this.$refs.validationForm.validate()) {
        this.formatPayload();
        this.isLoadingSave = true;
        setTimeout(() => {
          if (this.isEdit || this.isCriticizedBroker) {
            this.updateDeleteMovementRecordService();
          } else {
            this.deleteMovementRecordService();
          }
        }, 500);
      } else {
        this.setSnackbarCustomize('error', 'Preencha os campos obrigatórios para prosseguir');
      }
    },
    formatPayload() {
      this.formatDate = this.endDate ? this.formatter.formatDateBRtoString(this.endDate) : null;
      this.formattedResignationDate = this.resignationDate ? this.formatter.formatDateBRtoString(this.resignationDate) : null;
      if (this.isEdit || this.isCriticizedBroker) {
        this.formatPayloadEditAndCriticizedBroker();
      } else {
        this.formatPayloadCreateAndCriticizedCarrier();
      }

      if (this.isFreeMovement) {
        this.payload.holderRegistrationPlate = this.holderInformationData.registrationPlate ? this.holderInformationData.registrationPlate : null;
        this.payload.holderCardNumber = this.holderInformationData.cardNumber ? this.holderInformationData.cardNumber : null;
      }
    },
    formatPayloadCreateAndCriticizedCarrier() {
      this.payload = {
        contract: this.contractedPlanData.contractId ? this.contractedPlanData.contractId : null,
        subcontract: this.contractedPlanData.subContractId ? this.contractedPlanData.subContractId : null,
        financialGroup: this.contractedPlanData.financialGroupId ? this.contractedPlanData.financialGroupId : null,
        plan: this.contractedPlanData.planId ? this.contractedPlanData.planId : null,
        dependents: [],
        beneficiaryContractedPlanId: this.beneficiaryData.contractPlanId ? this.beneficiaryData.contractPlanId : null,
        exclusionReasonCode: this.exclusionReasonCode ? this.exclusionReasonCode : null,
        endDate: this.formatDate,
        documents: this.documentFormatted(),
        planExtension: !!this.planExtension,
        contributor: !!this.contributor,
        contributionPeriod: this.contributionPeriod ? this.contributionPeriod : null,
        deleteHolder: true,
        holderCpf: this.holderInformationData.document ? this.formatter.removeNonDigit(this.holderInformationData.document) : null,
        holderName: this.holderInformationData.name ? this.holderInformationData.name : null,
        holderRegistrationPlate: this.holderInformationData.registrationPlate,
        holderCardNumber: this.holderInformationData.cardNumber,
        resignationDate: this.formattedResignationDate,
        familyCode: this.familyCode,
        familyCodeSequential: this.familyCodeSequential,
        skipDuplicityCheck: this.skipDuplicityCheck ? this.skipDuplicityCheck : false,
      };

      if (this.isCriticizedCarrier) {
        this.payload.movementRecordParentId = this.movementRecord.id;
        this.payload.beneficiaryContractedPlanId = this.movementRecord.beneficiaryContractedPlanId ? this.movementRecord.beneficiaryContractedPlanId : null;
        this.payload.internalCriticisms = this.internalCriticisms;
      }
    },
    formatPayloadEditAndCriticizedBroker() {
      this.payload = {
        financialGroupId: this.contractedPlanData.financialGroupId ? this.contractedPlanData.financialGroupId : null,
        contractId: this.contractedPlanData.contractId ? this.contractedPlanData.contractId : null,
        subContractId: this.contractedPlanData.subContractId ? this.contractedPlanData.subContractId : null,
        planId: this.contractedPlanData.planId ? this.contractedPlanData.planId : null,
        exclusionReasonCode: this.exclusionReasonCode ? this.exclusionReasonCode : null,
        endDate: this.formatDate,
        contributor: this.contributor ? this.contributor : false,
        planExtension: this.planExtension ? this.planExtension : null,
        contributionPeriod: this.contributionPeriod ? this.contributionPeriod : null,
        holderName: this.holderInformationData.name ? this.holderInformationData.name : null,
        holderCpf: this.holderInformationData.document ? this.formatter.removeNonDigit(this.holderInformationData.document) : null,
        resignationDate: this.formattedResignationDate,
        documents: this.documentFormatted(),
        familyCode: this.familyCode,
        familyCodeSequential: this.familyCodeSequential,
        skipDuplicityCheck: this.skipDuplicityCheck ? this.skipDuplicityCheck : false,
      };
    },
    async deleteMovementRecordService() {
      let criticsm = [];

      if (sessionStorage.getItem('criticismRules')) {
        criticsm = JSON.parse(sessionStorage.getItem('criticismRules')).filter((movement) => movement.classification && movement.classification === 'INTERNAL').map((movement) => movement.id);
      }

      this.payload = lodash.cloneDeep({
        ...this.payload,
        internalCriticisms: criticsm,
        persistWithCriticism: this.persistWithCriticism ? this.persistWithCriticism : false,
      });

      await this.processMovementRecordService(this.movementRecordService.Delete, this.payload, this.isFreeMovement);
    },
    async updateDeleteMovementRecordService() {
      this.payload = {
        ...this.payload,
        persistWithCriticism: this.persistWithCriticism ? this.persistWithCriticism : false,
      };
      await this.processMovementRecordService(this.movementRecordService.UpdateDeleteMovementRecordById, this.movementRecord.id, this.payload, this.isFreeMovement, this.isCriticizedBroker);
    },
    async processMovementRecordService(serviceMethod, ...args) {
      this.violations = [];
      this.overlay = true;

      try {
        const response = await serviceMethod.call(this.movementRecordService, ...args);
        const { recordList } = response.data;
        const allWithStatusWaitingRulesApproval = recordList.every((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS');

        this.waitingRulesApprovalIds = recordList.filter((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS').map((movement) => movement.id);

        if (allWithStatusWaitingRulesApproval) {
          const releaseMovementData = {
            beneficiaryName: this.holderInformationData.name,
            movementType: this.movementType,
          };

          this.$refs.ReleaseMovementModal.open(releaseMovementData);
        } else {
          await this.updateStatus();
        }
      } catch (error) {
        this.overlay = false;
        this.isLoadingSave = false;
        this.documentsData = [];

        if (error.response && error.response.status === 422) {
          this.violations = [...error.response.data.violations];

          if (!this.areAllowedToCriticize()) return;

          this.$refs.CriticismHandlingModal.open();
        }
      }
    },
    areAllowedToCriticize() {
      if (!this.hasPermission('sdi_mov_prosseguir_critica') && this.violations && this.violations.length > 0) {
        this.setSnackbarCustomize('error', 'Erro de validação de regras no preenchimento do formulário');
        return false;
      }

      return true;
    },
    observerContractedPlan(data) {
      this.contractedPlanData = data;

      this.documentParams.contractId = this.contractedPlanData.contractId;
      this.documentParams.hasGracePeriod = this.contractedPlanData.hasGracePeriod ? true : false;

      setTimeout(() => {
        this.startSearch = true;
      }, 500);
    },
    observerHolderInformationData(data) {
      this.holderInformationData = data;
    },
    onClickBack() {
      this.redirectRouter('ManualMovementDeleteSearch', { isRHProtegido: this.isRHProtegido });
    },
    onClickCancel() {
      let routeName = '';
      if (this.isEdit || this.isCriticizedBroker || this.isCriticizedCarrier) {
        routeName = 'Movement';
      } else {
        routeName = this.isRHProtegido ? 'HomeRHProtegido' : 'Movement';
      }

      this.redirectRouter(routeName, { isRHProtegido: this.isRHProtegido });
    },
    async loadDocumentsByMovementRecordId() {
      const queryString = `?movementRecordId=${this.movementRecord.id}`;
      await this.documentService.FindDocumentByFilters(queryString).then(async (response) => {
        if (response && response.data && response.data.length > 0) {
          if (this.documentsList && this.documentsList.length > 0) {
            this.documentsList.forEach((documentList) => {
              response.data.forEach((document) => {
                if (documentList.name === document.documentType.name) {
                  // eslint-disable-next-line no-param-reassign
                  documentList.file = new File([document.name], document.name, { type: 'image/png' });
                  // eslint-disable-next-line no-param-reassign
                  documentList.documentTypeId = documentList.id;
                  // eslint-disable-next-line no-param-reassign
                  documentList.id = document.id;
                  // eslint-disable-next-line no-param-reassign
                  documentList.name = document.name;
                  // eslint-disable-next-line no-param-reassign
                  documentList.loadingDownload = false;
                }
              });
            });
          }
          this.loadDocumentsInData();
        }
      });
    },
    async loadDocumentsInData() {
      this.documentsList.forEach((document) => {
        this.documentsData.push(document);
      });
    },
    documentFormatted() {
      const documentFormatted = this.documentsList
        .filter((element) => element.file !== null && typeof element.file !== 'undefined')
        .map((element) => ({ id: element.idRecentlyUploaded ? element.idRecentlyUploaded : element.id }));
      return documentFormatted;
    },
    openConfirmReturnModal() {
      this.confirmReturnModal = true;
    },
    closeConfirmReturnModal() {
      this.$refs.ReleaseMovementModal.close();

      if (!this.isEdit && !this.isCriticizedBroker && !this.isCriticizedCarrier) {
        const releaseMovementData = {
          beneficiaryName: this.holderInformationData.name,
          movementType: this.movementType,
        };

        this.$refs.TransitionMovementModal.open(releaseMovementData);
      } else {
        this.goToMovementPage();
      }
    },
    backToSeachHolderDependentDeletePage() {
      this.$refs.TransitionMovementModal.close();
      this.redirectRouter('ManualMovementDeleteSearch', { isRHProtegido: this.isRHProtegido }, { creation: true })
    },
    goToMovementPage() {
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
      this.confirmReturnModal = false;
      this.documentsData = [];
      this.isLoadingSave = false;
      this.overlay = false;
    },
    async loadAuthorityMovement() {
      this.hasAuthorityReleaseMovement = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais_liberar_movimentacao' });
    },
    async updateStatus() {
      const movementRecordService = new MovementRecordService();
      this.$refs.ReleaseMovementModal.close();

      if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
        const request = {
          ids: [...this.waitingRulesApprovalIds],
          status: 'VALID',
        };

        await movementRecordService.UpdateStatusFromMultipleMovements(request).then(() => {
          this.releaseMessageType = this.successReleaseMessageType;
          this.closeConfirmReturnModal();
          this.setSnackbarCustomize('success', 'Movimentação liberada com sucesso');
        }).catch(() => {
          this.releaseMessageType = this.errorReleaseMessageType;
          this.closeConfirmReturnModal();
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao liberar a movimentação');
        });
      } else {
        this.releaseMessageType = this.errorReleaseMessageType;
        this.closeConfirmReturnModal();
        this.setSnackbarCustomize('error', 'Você não tem permissão para liberar a movimentação');
      }
    },
    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
    async loadContributoryInfo() {
      if (this.contract.xipp_contributory_id === null ||
        "Sem Contributariedade".localeCompare(this.contract.xipp_contributory_id) === 0) {
          this.contributor = false;
      } else {
        const queryParams = {
          contractId: this.contract.id,
          planId: this.beneficiaryData.planId ? parseInt(this.beneficiaryData.planId) : this.movementRecord.plan,
          beneficiaryType: this.beneficiaryData.isHolder ? 'titular' : 'dependente',
        };
        await this.contractContributoryService.CountByContractIdAndPlanIdAndBeneficiaryType(new URLSearchParams(queryParams)).then(async (response) => {
          const contractContributory = await response.data;
          this.contributor = contractContributory > 0 ? true : false;
        });
      }
    }
  },

  created() {
    this.verifyFreeMovement();
    this.cancelationReasonService = new CancelationReasonService();
    this.documentTypeService = new DocumentTypeService();
    this.documentService = new DocumentService();
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.contractService = new ContractService();
    this.kinshipService = new KinshipService();
    this.movementRecordService = new MovementRecordService();
    this.userUtils = new UserUtils();
    this.carrierService = new CarrierService();
    this.contractContributoryService = new ContractContributoryService();
  },
};
</script>
